<template>
  <div class="space-align-container">
    <div class="space-align-row">
      <div class="space-align-block">
        <a-space align="center">
          <a-image :width="300" src="./assets/AI_images/connector_2.png" :preview="false"/>
          <p style="text-align:left; font-size:large">
            <span style="color: red; font-weight: bold;">conXmate-50</span>  A cutting-edge tool that simplifies the process of configuring 
            and sourcing 50-ohm coaxial connectors. It offers a wide array of options, 
            such as the commonly sought-after SMA male to female adapters, ensuring that every need is met with precision. 
            The platform is designed for quick configuration, allowing users to find the exact connector they 
            require without delay. Pricing is kept fair and competitive, ensuring value for every purchase. Moreover, 
            the delivery process is transparent, with real-time updates that provide a clear view of order status. 
            The simplicity of the interface means that obtaining the ideal product is a matter of a few easy steps. 
            Experience the efficiency of conXmate and streamline the way you source your coaxial connectors.
          </p>
        </a-space>
      </div>
      
      <div class="space-align-block">
        <a-space align="center">
          <a-image :width="300" src="./assets/AI_images/module_02.png" :preview="false"/>
          <p style="text-align:left; font-size:large">
            Our cutting-edge IoT modules are designed around advanced communication protocols such as 
            Bluetooth, WiFi, LoRa, Zigbee, and RFID. 
            These modules seamlessly bridge the gap between hardware and software, 
            enabling seamless data exchange. 
            From environmental monitoring that ensures a sustainable future to building security solutions that offer peace of mind, 
            our products empower you to shape a smarter, safer world.
          </p>
         
        </a-space>
      </div>
    </div>
  
    <div class="space-align-row">
      <div class="space-align-block">
        <a-space align="center">
        
          <p style="text-align:right; font-size:large">
            Explore our diverse range of antenna solutions tailored for indoor, outdoor, 
            and enclosure applications. Our antennas power an array of scenarios, 
            from optimizing home networks with high-performance routers to ensuring reliable communication in expansive outdoor communication base stations. 
            Whether it's a residential setting or an industrial environment, our antennas guarantee exceptional connectivity.
          </p>
          <a-image :width="300" src="./assets/AI_images/antenna_03.png" :preview="false"/>
        </a-space>
      </div>
      <div class="space-align-block">
        <a-space align="center">
          <p style="text-align:left; font-size:large">
            <span style="color: red; font-weight: bold;">Pigtail-50</span> Delve into our RF cable assembly solutions, 
            meticulously crafted with premium 50-ohm cables. 
            These assemblies seamlessly integrate connectors including SMA, N-Type, TNC, MMCX, Micro-connector(compatible with IPEX, Amphenol), 
             SMP, SMB, 2.92mm, 2.4mm, 1,85mm and more, 
            ensuring impeccable signal quality across frequencies up to 20 GHz. From laboratory research to industrial applications, 
            our assemblies embody precision and reliability.
          </p>
          <a-image :width="300" src="./assets/AI_images/cable_02.png" :preview="false"/>
        </a-space>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProductSection'
}
</script>

<style scoped>
.space-align-container {
  display: flex;
  flex-wrap: wrap;
}

.space-align-row {
  display: flex;
  flex-wrap: wrap;
  width: 90%;
}

.space-align-block {
  margin: 8px 4px;
  border: 1px solid #ffffff;
  border-radius: 15px;
  padding: 4px;
  flex: 1 1 45%; /* Adjust the flex-basis as needed */
  box-sizing: border-box;
}

.space-align-block P {
  font-size: 28px; /* Change the desired font size */
}

.space-align-block .mock-block {
  display: inline-block;
  padding: 8px 8px 8px;
  background: rgba(150, 150, 150, 0.2);
}


</style>